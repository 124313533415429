import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import Lottie from "react-lottie";
import Slider from "react-slick";
import HTMLReactParser from "html-react-parser";

import swipe from "components/lotties/swipe.json";
import { Arrow } from "components/icons";

import { black, pink, white } from "theme/colors";
import { toRem, breakpoints } from "utils/mixins";
import { p_alternative } from "theme/typography";

//STYLES
const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  position: relative;

  .intro-image {
    position: relative;
    width: 50vw;
    height: 100vh;
    display: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .sliderWrapper {
    width: 100%;
    background-color: ${pink.default};
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    * {
      outline: none;
    }
    .slick-slider {
      width: 100%;
    }

    &-letter {
      font-family: "Scribble Box";
      font-weight: normal;
      font-size: 80px;
      line-height: 80px;
      font-style: normal;
      color: ${white.default};
      position: absolute;
      left: 20px;
      top: 132px;
    }
  }

  .intro-textC {
    width: 100%;
    min-height: 100vh;
    display: inline-flex !important;
    align-items: flex-start;
    justify-content: start;
    padding-bottom: ${toRem(220)};
    .intro-text {
      height: 100%;
      padding: ${toRem(20)};
      position: relative;
      transform: translateY(180px);
      p {
        margin-top: 0;
        font-weight: normal;
        font-style: normal;
        color: ${white.default};
        a {
          text-decoration: underline;
        }
      }
    }
  }

  @media (min-width: 767px) {
    .sliderWrapper {
      &-letter {
        font-size: 125px;
        top: unset;
        line-height: 125px;
        transform: translateY(-100%);
      }
    }
    .intro-textC {
      align-items: center;
    }
  }

  //1024
  @media (min-width: 799px) and (orientation: landscape) {
    height: 100vh;
    overflow: hidden;
    .intro-image {
      display: block;
    }
    .sliderWrapper {
      width: 50vw;
      background-color: ${white.default};
      &-letter {
        color: ${pink.default};
        font-size: 125px;
        top: 150px;
        left: 50px;
        line-height: 125px;
        transform: translateY(0);
      }
    }
    .intro-textC {
      padding-bottom: 0;
      align-items: flex-start;
      padding: 0 20px 0 50px;
      padding-top: 275px;
      padding-bottom: 150px;
      height: 100vh;
      .intro-text {
        padding: 0;
        overflow-y: scroll;
        max-width: ${toRem(700)};
        transform: translateY(0);

        ::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
          //border-radius: 10px;
          background-color: white;
        }

        ::-webkit-scrollbar {
          width: 6px;
          background-color: white;
        }

        ::-webkit-scrollbar-thumb {
          //border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
          background-color: ${pink.default};
        }
        p {
          color: ${black.default};
        }
      }
    }
  }

  @media (min-height: 800px) {
    .sliderWrapper {
      &-letter {
        top: 50%;
        transform: translateY(-200px);
      }
      .intro-textC {
        align-items: center;
        padding: 0 20px 0 50px;
        .intro-text {
          transform: translateY(50px);
          height: auto;
        }
      }
    }
  }

  @media (min-width: 1921px) {
    .intro-text {
      max-width: unset;
      margin-right: 15vw;
    }
  }
`;

const Controls = styled.div`
  position: absolute;
  //top: unset;
  bottom: 80px;
  z-index: 20;
  display: flex;
  .lottie {
    max-width: 200px;
    z-index: 5;
    transition: 250ms;
    &-hide {
      visibility: none;
      opacity: 0;
    }
    @media ${breakpoints.laptop} {
      display: none;
    }
  }
  button {
    display: none;
    position: relative;
    padding: 10px;
    svg {
      width: 20px;
    }
    &.prev {
      right: unset;
      left: 0;
      opacity: 0;
      pointer-events: none;
      &--show {
        opacity: 1;
        pointer-events: all;
      }
      svg {
        transform: rotate(-180deg);
      }
    }
    &.next {
      opacity: 0;
      pointer-events: none;
      &--show {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  @media ${breakpoints.laptop} {
    right: 20px;
    button {
      display: block;
    }
  }
  @media (min-width: 1400px) {
    bottom: 150px;
    left: 700px;
    right: unset;
    transform: translateX(-100%) translateY(50%);
  }
  @media (min-width: 1921px) {
    left: unset;
    right: 15vw;
    transform: translateY(50%);
  }
`;

const Ind = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  .info {
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      position: relative;
      transform: rotate(90deg);
      width: 2px;
    }
    span {
      ${p_alternative}
      font-size: 18px;
      line-height: 18px;
      position: relative;
      display: block;
    }
  }

  @media ${breakpoints.laptop} {
    //padding: 20px 40px;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(-90deg);
  }
  @media (min-width: 1400px) {
    //bottom: 200px;
    right: calc(700px + 50vw);
    left: unset;
    transform: translateX(100%) translateY(-50%) rotate(-90deg);
  }
  @media (min-width: 1921px) {
    right: unset;
    left: 15vw;
    transform: translateY(-50%) rotate(-90deg);
  }
`;

//STYLES

const ExpTemplate = (props) => {
  //PROPS
  const {
    data: {
      page: { expressions }
    },
    setColor,
    dimensions
  } = props;
  //PROPS

  //DEFS
  const settings = {
    dots: false,
    arrows: false,
    adaptiveHeight: true,
    vertical: dimensions.width >= 1024 ? true : false,
    verticalSwiping: dimensions.width >= 1024 ? true : false,
    fade: false,
    afterChange: (e) => {
      setSlide(e);
    },
    beforeChange: (e) => {
      document.querySelector(".lottie").classList.add("lottie-hide");
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: swipe,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  //DEFS

  //STATES
  const [slide, setSlide] = useState(0);
  //STATES

  //REF
  const slider = useRef(null);
  //REF

  //FUNCS
  const nextSlide = () => {
    slider.current.slickNext();
  };

  const prevSlide = () => {
    slider.current.slickPrev();
  };
  //FUNCS

  //USEEFFECTS
  useEffect(() => {
    if (dimensions.width >= 1024) {
      setColor(true);
    } else {
      setColor(false);
    }
    return () => {
      setColor(true);
    };
  }, [dimensions]);
  //USEEFFECTS

  const LetterShow = () => {
    const [letter, setLetter] = useState("");

    useEffect(() => {
      setLetter(expressions.content[slide].letter);
    }, [slide]);

    return <span className="sliderWrapper-letter">{letter}</span>;
  };

  return (
    <Container>
      <Ind>
        <div className="info">
          <span>{expressions.content[slide].letter}</span>
          <img src="/images/line.svg" alt="line separator" />
          <span>Z</span>
        </div>
      </Ind>
      <div className="intro-image">
        <img
          src={expressions.image.localFile.childImageSharp.fluid.src}
          srcSet={expressions.image.localFile.childImageSharp.fluid.srcSet}
          alt={expressions.image.altText}
        />
      </div>
      <div className="sliderWrapper">
        {LetterShow()}
        <Slider {...settings} ref={slider}>
          {expressions.content &&
            expressions.content.map((elem, index) => {
              return (
                <div className="intro-textC">
                  <div className="intro-text" key={index}>
                    {HTMLReactParser(elem.text)}
                  </div>
                </div>
              );
            })}
        </Slider>
        <Controls>
          <div className="lottie">
            <Lottie options={defaultOptions} />
          </div>
          <button
            id="al-prev"
            className={slide >= 1 ? "prev prev--show" : "prev"}
            onClick={() => prevSlide()}
            aria-label="Ir para próximo anterior"
          >
            <Arrow color={pink.default} />
          </button>

          <button
            id="al-next"
            onClick={() => nextSlide()}
            className={
              slide < expressions.content.length - 1
                ? "next next--show"
                : "next"
            }
            aria-label="Ir para Alerta seguinte"
          >
            <Arrow color={pink.default} />
          </button>
        </Controls>
      </div>
    </Container>
  );
};

export const query = graphql`
  query Expressoes($id: String) {
    page: wpPage(id: { eq: $id }) {
      expressions {
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                src
                srcSet
              }
            }
          }
        }
        content {
          letter
          text
        }
      }
    }
  }
`;

export default ExpTemplate;
